/**
 * This script will:
 *   - Parse all of the anchor tags in the document
 *   - Filter for anchor tags pointing to our driver/owner web application
 *     (app.hyrecar.com)
 *   - Append a subset of parameters from the current url (hyrecar.com) to those anchor tags to pass to our
 *     driver/owner web application
 */
export const HC_GCLID_APPEND_CLICK_VERSION="1.0.3";

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/NodeList
 * @returns { Nodelist }
 */
export function fetchAllAnchorElements() {
  return document.querySelectorAll("a");
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Node
 * @param { Node } node
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String
 * @returns { String }
 */
export function parseAnchorNodeUrl(node) {
  return node.getAttribute("href");
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/undefined
 * @returns { String | undefined }
 */
export function parseGoogleClickIdFromUrl() {
  const parameters = new URLSearchParams(location.search);
  return parameters.get("gclid");
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String
 * @param { String } url
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String
 * @returns { String }
 */
export function modifyUrlParameters(url) {
  if (!url) return;
  if (!url.includes("://app.hyrecar.com")) return;
  const parametersToAppend = new URLSearchParams(location.search);

  const urlToModify = new URL(url);

  const parametersToModify = urlToModify.searchParams;

  const parametersKeys = ["gclid", "affiliate_source", "affiliate_source_id", "affiliate_referrer", "utm_source", "utm_medium", "utm_campaign"];

  parametersToAppend.forEach((value, key) => {
    if(parametersKeys.includes(key)) {
      parametersToModify.append(key, value);
    }
    /*
      When gclid is included in the main url (hyrecar.com),
      the affiliate_source and affiliate_source_id
      are not included so we need to add those manually to the anchor url
     */
    if(key === "gclid") {
      parametersToModify.append("affiliate_source", "google_ads");
      parametersToModify.append("affiliate_source_id", value);
    }
  })

  urlToModify.search = parametersToModify.toString();

  return urlToModify.toString();
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String
 * @param { String } modifiedUrl
 * https://developer.mozilla.org/en-US/docs/Web/API/Node
 * @param { Node } node
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/undefined
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/void
 * @returns { undefined | void  }
 */
export function updateAnchorNodeUrl(modifiedUrl, node) {
  if (!modifiedUrl || !node) return;
  node.setAttribute("href", modifiedUrl);
}

/**
 * Users search our pages Google
 * We need to specific parameters to be passed to our driver/owner web application at all times
 * Append a subset of parameters to all URLs if it exists
 */
export function appendGoogleClickIdToAllHyrecarUrls() {
  console.log("HC Append Version", HC_GCLID_APPEND_CLICK_VERSION);

  const anchorNodes = fetchAllAnchorElements();

  for (const node of anchorNodes) {
    const url = parseAnchorNodeUrl(node);
    const modifiedUrl = modifyUrlParameters(url);
    updateAnchorNodeUrl(modifiedUrl, node);
  }
}

